
.side-menu {
  /* width: 25%; */
  background-color: black;
  padding: 4px;
  display: none;
  left: -250px;
  width: 250px;
  padding: 10px;
  display: inline;
}

.chat-box {
  flex: 1;
  position: relative;
  background: linear-gradient(to top, #353740, #444654);
  z-index: 110;
}

.chat-log {
  text-align: left;
  margin-left: 1%;
}

.circular-progress {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mobile-new-chat-button {
  position: sticky;
  top: 0px;
  z-index: 200;
}

.white-buttons {
  display: flex;
  position: fixed;
  bottom: 0px;
  background-color:  black;/*#202123;*/
  z-index: 90;
  width: 260px;
  height: 400px;
}



@media (min-width: 500px) {
  .mobile-new-chat-button {
    display: none;
  }

  .mobile-login-button {
    display: none;
  }

  .mobile-store-button {
    display: none;
  }
  .card {
    background-color: #353740;
  }
}

@media (max-width: 900px) {
  .chat-log {
    text-align: left;
    margin-left: 15%;
  }
}

.response-separator{
  margin-top: 30px;
}

.side-menu.hidden {
  display: none;
}